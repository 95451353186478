<script setup lang="ts">
const links = [
  { text: 'Accueil', to: '/' },
  { text: 'La plateforme d\'accompagnement', to: '/plateforme-accompagnement' },
  { text: 'Formation accompagnement intuitif', to: '/formations/accompagnement-intuitif' },
  { text: 'Formation à la Paix Intérieure', to: '/formations/accompagnement-paix-interieure' },
  { text: 'La plateforme d\'accompagnement', to: '/plateforme-accompagnement' },
  { text: 'Nos tarifs', to: '/nos-offres' },
  { text: 'À propos', to: '/qui-sommes-nous' },
  { text: 'Article', to: '/articles' },
  { text: 'FAQ', to: '/faq' },
  { text: 'Contact', to: '/contact' },
]
</script>

<template>
  <footer class="grid grid-cols-1 items-center items-center self-center justify-center justify-center px-10 py-10 container lg:grid-cols-3 lt-lg:gap-8">
    <div class="flex flex-col items-center justify-center text-lavender">
      <div class="w-full flex flex-col lt-lg:items-center lt-lg:justify-center">
        <h3 class="mb-6 text-xl font-laviossa">
          Plan du site
        </h3>

        <p class="text-md max-w-300px leading-7 font-gravity">
          <template v-for="(link, i) in links" :key="link.text">
            <NuxtLink :to="link.to" class="whitespace-nowrap">
              {{ link.text }}
            </NuxtLink>
            <span v-if="i < links.length - 1">&nbsp;| </span>
          </template>
        </p>

        <NuxtLink to="https://eliah-files.s3.fr-par.scw.cloud/rgpd/SITE%20-%20MENTIONS%20LEGALES%20.pdf" class="mb-0.5 mt-8 text-lg text-lavender-600 font-gravity" target="_blank">
          Mentions légales
        </NuxtLink>

        <NuxtLink to="https://eliah-files.s3.fr-par.scw.cloud/rgpd/SITE%20-%20POLITIQUE%20DE%20CONFIDENTIALIT%C3%89.pdf" class="mb-0.5 text-lg text-lavender-600 font-gravity" target="_blank">
          Politique de confidentialité
        </NuxtLink>

        <NuxtLink to="https://eliah-files.s3.fr-par.scw.cloud/rgpd/SITE%20-%20CONDITIONS%20G%C3%89N%C3%89RALES%20DE%20VENTE%20%28CGV%29%20ET%20D%27UTILISATIONS%20%28%20CGU%29.pdf" class="text-lg text-lavender-600 font-gravity" target="_blank">
          Conditions générales de vente
        </NuxtLink>
      </div>
    </div>

    <NuxtImg src="/img/eliah-logo-hjt.png" alt="Eliah Logo" sizes="sm:50vw md:50vw lg:50vw xl:50vw" class="mx-auto block max-w-xs w-full" />

    <div class="flex flex-col items-center justify-center lg:items-end">
      <div class="flex flex-col items-center justify-center lg:justify-end">
        <h3 class="mb-6 text-xl text-lavender font-laviossa">
          Renseignements
        </h3>

        <VBtn pre-icon="i-teenyicons:envelope-solid" to="/contact">
          <span class="px-2 py-1 text-4.5 font-ubuntu">
            Contactez-nous
          </span>
        </VBtn>

        <div class="mt-4 text-center text-lg font-ubuntu">
          Suivez-nous sur
        </div>

        <div class="inline-flex items-center text-lg">
          <VBtnIcon icon="i-ph:linkedin-logo-bold" href="https://www.linkedin.com/company/eliah-institut" target="_blank" />
          <VBtnIcon icon="i-ph:instagram-logo-bold" href="https://www.instagram.com/eliah_institut" target="_blank" />
        </div>
      </div>
    </div>
  </footer>
</template>

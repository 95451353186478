<script setup lang="ts">
useHead({
  titleTemplate: title => title ? `${title} - Eliah Pro` : 'Eliah Pro',
})
</script>

<template>
  <div class="min-h-screen w-full flex flex-col bg-void text-slate-200">
    <VLayoutHeaderProfessionnels />

    <main class="flex grow flex-col lt-xl:min-h-screen children:grow">
      <slot />
    </main>

    <VLayoutFooterProfessionnels />
  </div>
</template>

<script setup lang="ts">
import { navigateTo } from '#app'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { useToggle } from '@vueuse/core'

const runtimeConfig = useRuntimeConfig()

const [showMenu, toggleMenu] = useToggle()

interface LinkContent {
  text: string
  to?: string
  href?: string
  icon?: string
  additionnalClasses?: string
  key: string
}

interface Link extends LinkContent {
  dropdown?: {
    links: LinkContent[]
  }
}

const links: Link[] = [
  { text: 'Accueil', to: '/', key: 'home' },
  { text: 'La plateforme d\'accompagnement', to: '/plateforme-accompagnement', key: 'plateform' },
  {
    text: 'Nos formations',
    key: 'formations',
    dropdown: {
      links: [
        {
          text: 'Formation à l\'accompagnement intuitif',
          to: '/formations/accompagnement-intuitif',
          key: 'intuitif',
        },
        {
          text: 'Formation à l\'accompagnement Paix Intérieure',
          to: '/formations/accompagnement-paix-interieure',
          key: 'paix-interieure',
        },
      ],
    },
  },
  { text: 'Tarifs', to: '/nos-offres', key: 'prices' },
  { text: 'À propos', to: '/qui-sommes-nous', key: 'about' },
  { text: 'FAQ', to: '/faq', key: 'faq' },
  { text: 'Articles', to: '/articles', key: 'articles' },
]

const NuxtLink = resolveComponent('NuxtLink')
</script>

<template>
  <div v-auto-animate class="w-full">
    <div key="header" class="flex items-center justify-between">
      <div class="flex items-center px-3 py-3.5">
        <NuxtLink to="/">
          <NuxtImg
            class="max-w-inherit min-h-11 w-[180px] transition-all 2xl:ml-20"
            src="/img/eliah-logo-hjt.png"
            alt="Eliah Logo"
            sizes="sm:30vw md:33vw lg:36vw xl:40vw"
            quality="80"
            format="webp"
          />
        </NuxtLink>
      </div>

      <div class="why w-full flex items-center justify-center font-ubuntu lt-xl:hidden">
        <template
          v-for="link in links"
          :key="link.key"
        >
          <div v-if="link.icon" :class="link.icon" class="from-lemon to-sunset bg-gradient-to-b" />
          <component
            :is="link.to ? NuxtLink : 'span'"
            v-if="!link.dropdown"
            :to="link.to"
            :href="link.href"
            :class="{
              [link.additionnalClasses ?? '']: link.additionnalClasses,
            }"
            class="relative mr-0 text-zinc-300 transition-all duration-300 before:invisible before:absolute before:bottom-0 before:left-0 before:h-0.5 before:w-0 before:bg-lavender before:transition-all before:duration-300 before:content-empty before:hover:visible before:hover:w-full lg:mr-2 md:mr-1 xl:mr-6 hover:text-zinc-100"
          >
            {{ link.text }}
          </component>

          <div
            v-else
            class="mr-0 text-right lg:mr-2 md:mr-1 xl:mr-6"
          >
            <Menu
              :id="`dropdown-${link.key}`"
              as="div"
              class="relative inline-block text-left"
              :data-dropdown-key="link.key"
            >
              <MenuButton :id="`dropdown-${link.key}-button`" class="w-full inline-flex justify-center rounded-md text-zinc-300">
                {{ link.text }}
                <span
                  class="i-heroicons:chevron-down-20-solid ml-2 h-5 w-5 text-violet-200 -mr-1 hover:text-violet-100"
                  aria-hidden="true"
                />
              </MenuButton>

              <Transition
                enter-active-class="transition duration-100 ease-out"
                enter-from-class="transform scale-95 opacity-0"
                enter-to-class="transform scale-100 opacity-100"
                leave-active-class="transition duration-75 ease-in"
                leave-from-class="transform scale-100 opacity-100"
                leave-to-class="transform scale-95 opacity-0"
              >
                <MenuItems
                  class="absolute right-0 mt-2 origin-top-right border-2 border-lavender rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
                >
                  <div class="flex flex-col divide-y divide-lavender">
                    <MenuItem
                      v-for="item in link.dropdown.links"
                      :key="item.key"
                      v-slot="{ close }"
                    >
                      <component
                        :is="item.to ? 'a' : 'span'"
                        :href="item.to || item.href"
                        class="relative whitespace-nowrap px-4 py-2 text-void transition-all duration-300 first:rounded-t last:rounded-b hover:bg-void hover:text-white"
                        @click.prevent="close(); navigateTo(item.to || item.href, { external: !item.to })"
                      >
                        {{ item.text }}
                      </component>
                    </MenuItem>
                  </div>
                </MenuItems>
              </Transition>
            </Menu>
          </div>
        </template>
      </div>

      <div class="w-full flex flex-1 items-center justify-end transition-all 2xl:mr-20">
        <VBtn
          pre-icon="i-teenyicons:envelope-solid"
          to="/contact"
          class="mr-4 min-w-42 font-ubuntu lt-xl:hidden"
          theme="primary"
        >
          Contactez-nous
        </VBtn>

        <div class="min-w-35 flex justify-center gap-2 lt-sm:hidden">
          <div class="i-ph:sign-in-bold from-lemon to-sunset bg-gradient-to-b" />
          <NuxtLink
            :href="runtimeConfig.public.platformUrl"
            class="text-md relative text-zinc-300 font-ubuntu transition-all duration-300 before:invisible before:absolute before:bottom-0 before:left-0 before:h-0.5 before:w-0 before:bg-lavender before:transition-all before:duration-300 before:content-empty before:hover:visible before:hover:w-full hover:text-zinc-100"
          >
            Mon espace
          </NuxtLink>
        </div>

        <div class="flex lt-xl:hidden">
          <VBtnIcon icon="i-ph:linkedin-logo-bold" href="https://www.linkedin.com/company/eliah-institut" target="_blank" />
          <VBtnIcon icon="i-ph:instagram-logo-bold" href="https://www.instagram.com/eliah_institut" target="_blank" />
        </div>

        <VBtnIcon icon="i-ic:round-menu" class="xl:hidden" @click="toggleMenu()" />
      </div>
    </div>

    <div v-if="showMenu" key="mobile-menu" class="flex flex-col items-center justify-center gap-3 bg-void-400 pb-3 pt-8 xl:hidden">
      <div
        v-for="link in links"
        :key="link.key"
      >
        <component
          :is="link.to ? NuxtLink : 'span'"
          v-if="!link.dropdown"
          :to="link.to"
          :href="link.href"
          :class="{
            [link.additionnalClasses ?? '']: link.additionnalClasses,
          }"
          class="relative mr-0 text-zinc-300 transition-all duration-300 before:invisible before:absolute before:bottom-0 before:left-0 before:h-0.5 before:w-0 before:bg-lavender before:transition-all before:duration-300 before:content-empty before:hover:visible before:hover:w-full lg:mr-2 md:mr-1 xl:mr-6 hover:text-zinc-100"
        >
          {{ link.text }}
        </component>

        <div
          v-else
          class="mr-0 text-right lg:mr-2 md:mr-1 xl:mr-6"
        >
          <Menu
            :id="`dropdown-${link.key}`"
            as="div"
            class="relative inline-block text-left"
            :data-dropdown-key="link.key"
          >
            <MenuButton :id="`dropdown-${link.key}-button`" class="w-full inline-flex justify-center rounded-md text-zinc-300">
              {{ link.text }}
              <span
                class="i-heroicons:chevron-down-20-solid ml-2 h-5 w-5 text-violet-200 -mr-1 hover:text-violet-100"
                aria-hidden="true"
              />
            </MenuButton>

            <Transition
              enter-active-class="transition duration-100 ease-out"
              enter-from-class="transform scale-95 opacity-0"
              enter-to-class="transform scale-100 opacity-100"
              leave-active-class="transition duration-75 ease-in"
              leave-from-class="transform scale-100 opacity-100"
              leave-to-class="transform scale-95 opacity-0"
            >
              <MenuItems
                class="absolute right-0 z-10 mt-2 origin-top-right border-2 border-lavender rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
              >
                <div class="flex flex-col divide-y divide-lavender">
                  <MenuItem
                    v-for="item in link.dropdown.links"
                    :key="item.key"
                    v-slot="{ close }"
                  >
                    <component
                      :is="item.to ? 'a' : 'span'"
                      :href="item.to || item.href"
                      class="relative px-4 py-2 text-void transition-all duration-300 lg:whitespace-nowrap first:rounded-t last:rounded-b hover:bg-void hover:text-white"
                      @click.prevent="close(); toggleMenu(false); navigateTo(item.to || item.href, { external: !item.to })"
                    >
                      {{ item.text }}
                    </component>
                  </MenuItem>
                </div>
              </MenuItems>
            </Transition>
          </Menu>
        </div>
      </div>

      <VBtn pre-icon="i-teenyicons:envelope-solid" to="/contact" class="mt-6">
        Contactez-nous
      </VBtn>

      <div class="space-x-2">
        <VBtnIcon icon="i-ph:linkedin-logo-bold" href="https://www.linkedin.com/company/eliah-institut" target="_blank" />
        <VBtnIcon icon="i-ph:instagram-logo-bold" href="https://www.instagram.com/eliah_institut" target="_blank" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.why>* {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

@media (min-width: 1600px) {
  .why>* {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
</style>
